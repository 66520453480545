<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="商品名称">
                <el-input v-model="search.shangPinMC" size="small"/>
            </x-search-item>
            <x-search-item label="交易时间" textWidth="220px">
                <el-date-picker placeholder="起始时间" v-model="search.jiaoYiSJBegin" size="small" type="datetime" />
                <el-date-picker placeholder="结束时间" v-model="search.jiaoYiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="shangPinMC" label="商品名称"/>
            <el-table-column width="180" prop="huoHao" label="货号" />
            <el-table-column width="180" prop="tiaoMa" label="条码" />
            <el-table-column width="180" prop="zongXiaoLiang" label="总销量" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import XTableBase from "@/components/x/XTableBase";
    import * as service from "@/service/jygl/ShangPinXiaoShouMX";

    export default {
        name: "ChangXiaoShangPinList",
        mixins: [XTableBase],
        components: {},
        data() {
            this.refreshService = service.getChangXiaoList;
            this.service = service;
            return {
                search: {
                    shangPinMC: '',
                    jiaoYiSJBegin:'',
                    jiaoYiSJEnd:'',
                }
            }
        }
    }
</script>

<style scoped>
</style>
